import bg_01 from "../src/assets/backgrounds/bg_01.svg";
import user from "../src/assets/user.jpg";
import profile from "../src/assets/profile.png";
import notification from "../src/assets/notification.png";
import arterns_logo_1 from '../src/assets/logo/arterns_logo_1.png'
import arterns_logo_2 from '../src/assets/logo/arterns_logo_2.png'
import arterns_logo_3 from '../src/assets/logo/arterns_logo_3.png'

export const assets = {
  backgrounds: {
    bg_01,
  },
  user,
  notification,
  profile,
  logo:{
    arterns_logo_1,
    arterns_logo_2,
    arterns_logo_3
  }
};
