import React from 'react'
import { assets } from '../assets'

const SplashScreen = () => {
  return (
    <div className='h-[100vh] bg-gradient-to-tr from-primary to-secondary flex flex-col justify-center items-center'>
        <img src={assets.logo.arterns_logo_3} alt='Logo' className='w-80 ' />
    </div>
  )
}

export default SplashScreen