import { lazy } from "react";
const MembershipDetails = lazy(() =>
  import("./components/employee/employee_profile/MembershipDetails")
);
const JobDetails = lazy(() =>
  import("./components/employee/employee_profile/JobDetails")
);
const ContactDetails = lazy(() =>
  import("./components/employee/employee_profile/ContactDetails")
);
const PersonalDetailsDefault = lazy(() =>
  import("./components/employee/employee_profile/PersonalDetailsDefault")
);
const LoginPage = lazy(() => import("./pages/LoginPage"));
const EmployeeDashboard = lazy(() =>
  import("./components/employee/EmployeeDashboard")
);
const EmployeePayroll = lazy(() =>
  import("./components/employee/EmployeePayroll")
);
const EmployeeDashboardDefault = lazy(() =>
  import("./components/employee/EmployeeDashboardDefault")
);
const PersonalDetails = lazy(() =>
  import("./components/employee/employee_profile/PersonalDetails")
);
const Organisation = lazy(() =>
  import("./components/employee/organisation/Organisation")
);
const OrganisationDefault = lazy(() =>
  import("./components/employee/organisation/OrganisationDefault")
);
const OrganisationAdmins = lazy(() =>
  import("./components/employee/organisation/OrganisationAdmins")
);
const OrganisationSubAdmins = lazy(() =>
  import("./components/employee/organisation/OrganisationSubAdmins")
);
const OrganisationEmployees = lazy(() =>
  import("./components/employee/organisation/OrganisationEmployees")
);
const EmployeeLearningDefault = lazy(() =>
  import("./components/employee/employee_learning/EmployeeLearningDefault")
);
const Courses = lazy(() =>
  import("./components/employee/employee_learning/Courses")
);
const VideoPlayback = lazy(() =>
  import("./components/employee/employee_learning/VideoPlayback")
);

export const ALL_LINKS = {
  Login_Page: {
    pageLink: "/",
    view: LoginPage,
    loginRequire: false,
    show: {
      null: true,
      customer: true,
      admin: true,
    },
  },
  Employee_Dashboard: {
    pageLink: "/employee_dashboard/*",
    view: EmployeeDashboard,
    loginRequire: false,
    show: {
      null: true,
      customer: true,
      admin: true,
    },
  },
};

export const EMPLOYEE_LINKS = {
  AdminDashboardDefault: {
    pageLink: "",
    view: EmployeeDashboardDefault,
    loginRequire: true,
  },
  Payroll: {
    pageLink: "/payroll",
    view: EmployeePayroll,
    loginRequire: true,
  },
  PersonDetails: {
    pageLink: "/details/*",
    view: PersonalDetails,
    loginRequire: true,
  },
  Organisation: {
    pageLink: "/organisation/*",
    view: Organisation,
    loginRequire: true,
  },
  Learning: {
    pageLink: "/employee_learning",
    view: EmployeeLearningDefault,
    loginRequire: true,
  },
  Courses: {
    pageLink: "/courses",
    view: Courses,
    loginRequire: true,
  },
  VideoPlayback: {
    pageLink: "/video_playback",
    view: VideoPlayback,
    loginRequire: true,
  },
};

export const PERSONAL_DETAIL_LINKS = {
  PersonDetailsDefault: {
    pageLink: "",
    view: PersonalDetailsDefault,
    loginRequire: true,
  },
  ContactDetails: {
    pageLink: "/contact",
    view: ContactDetails,
    loginRequire: true,
  },
  JobDetails: {
    pageLink: "/job",
    view: JobDetails,
    loginRequire: true,
  },
  MembershipDetails: {
    pageLink: "/membership",
    view: MembershipDetails,
    loginRequire: true,
  },
};

export const ORGANISATION_LINKS = {
  OrganisationDefault: {
    pageLink: "",
    view: OrganisationDefault,
    loginRequire: true,
  },
  OrganisationAdmins: {
    pageLink: "/admins",
    view: OrganisationAdmins,
    loginRequire: true,
  },
  OrganisationSubAdmins: {
    pageLink: "/subadmins",
    view: OrganisationSubAdmins,
    loginRequire: true,
  },
  OrganisationEmployees: {
    pageLink: "/employees",
    view: OrganisationEmployees,
    loginRequire: true,
  },
};
