import { Suspense, useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ALL_LINKS } from "./constant";
import SplashScreen from "./pages/SplashScreen";
import AuthContext from "./store/AuthContext";
const App = () => {
  const authCtx=useContext(AuthContext);

  const PAGES=[
    ALL_LINKS.Login_Page,
    ALL_LINKS.Employee_Dashboard
  ]
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);



  // useEffect(()=>{
  //   if(authCtx.isLoggedIn && user.id)dispatch(fetchCart(user.id))
  // },[user.id])

  return (

    <BrowserRouter>
      <div id="" className="relative min-h-[100vh]">
        <div className="">
          {loading ? (
            <SplashScreen />
          ) : (
            <>
            <Suspense fallback={<div />}>
            <Routes>
              {PAGES.map((item, i) => {
                return ((item.loginRequire && item.show[authCtx.role]) ? (
                  <Route
                    key={i}
                    path={item.pageLink}
                    element={
                      authCtx.isLoggedIn ? (
                        <item.view />
                      ) : (
                        <Navigate to={ALL_LINKS.Login_Page.pageLink} />
                      )
                    }
                  />
                ):
                item.show[authCtx.role] && <Route key={i} path={item.pageLink} element={<item.view/>} />)
              })}
            </Routes>
          </Suspense>
            </>
          )}
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
